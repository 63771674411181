<template>
  <section class="text-right relative">
    <div class="mb-11">
      <div
        class="text-primary font-extrabold text-center font-custom sm:flex justify-center items-center"
        style="font-size: 42px"
      >
        <div>مقياس العُلو للتفكير الحاسوبي</div>
      </div>
      <div v-if="step == 3" class="container mx-auto">
        <div class="timer-count">
          {{ timerCount }}<span class="text-xs">minute</span>
        </div>
      </div>
    </div>

    <form-wizard
      @onComplete="submit"
      @onNextStep="nextStep()"
      @onPreviousStep="previousStep"
      ref="art1"
    >
      <tab-content title="البيانات الرئيسية" :selected="true">
        <div class="flex">
          <div class="w-full px-3">
            <form
              action="#"
              onsubmit="event.preventDefault()"
              name="submit-to-google-sheet"
              class="space-y-8 bg-transparent w-full mx-auto mt-6"
            >
              <div class="w-full">
                <div class="w-full px-2 mb-6">
                  <input
                    placeholder="الاسم"
                    v-model="name"
                    name="name"
                    id="name"
                    style="outline: none !important"
                    @input="result.account.name = name"
                    class="border h-12 rounded-md flex items-start border-gray-300 bg-transparent text-sm text-right w-full p-2"
                  />
                </div>
                <div class="w-full px-2 mb-6">
                  <input
                    placeholder="العمر"
                    v-model="age"
                    @input="result.account.age = age"
                    name="name"
                    type="number"
                    style="outline: none !important"
                    class="border h-12 rounded-md flex items-start border-gray-300 bg-transparent text-sm text-right w-full p-2"
                  />
                </div>
                <div class="w-full px-6 mb-6 text-right">
                  <div class="text-secondary secondary">الجنس</div>
                  <div class="w-full px-2">
                    <label for="male" class="px-3">ذكر</label>

                    <input
                      type="radio"
                      id="male"
                      name="gender"
                      value="male"
                      @change="result.account.gender = 1"
                    />
                  </div>

                  <div class="w-full px-2">
                    <label for="female" class="px-3">انثى</label>

                    <input
                      type="radio"
                      id="female"
                      name="gender"
                      value="female"
                      @change="result.account.gender = 0"
                    />
                  </div>
                </div>
                <div class="w-full px-6 mb-6 text-right">
                  <div class="text-secondary">العمل</div>

                  <div class="w-full px-2">
                    <label for="stu" class="px-3">طالب</label>
                    <input
                      type="radio"
                      id="stu"
                      name="position"
                      value="stu"
                      v-model="jop"
                      @change="result.account.position_id = 1"
                    />
                  </div>

                  <div
                    v-if="jop == 'stu'"
                    class="px-3 mt-2 flex w-full flex-wrap justify-end"
                    style="font-size: 15px"
                  >
                    <div
                      v-for="(item, i) in educationalٍtages"
                      :key="i"
                      class="mb-2"
                    >
                      <label :for="item.id" class="px-3"
                        >{{ item.name }}
                      </label>
                      <input
                        type="radio"
                        :id="item.id"
                        name="student"
                        :value="item.id"
                        v-model="stu"
                        @change="result.account.job_title = item.name"
                      />
                    </div>
                  </div>

                  <!-- <div class="flex justify-between items-center mt-2"> -->
                  <div class="px-2">
                    <label for="jop" class="px-3">موظف</label>

                    <input
                      type="radio"
                      id="jop"
                      name="position"
                      value="jop"
                      v-model="jop"
                      @change="result.account.position_id = 2"
                    />
                  </div>
                  <div v-if="jop == 'jop'" class="m-2 w-3/4l">
                    <input
                      placeholder="جهة العمل*"
                      v-model="work"
                      name="work"
                      type="text"
                      style="outline: none !important"
                      class="border h-12 rounded-md flex items-start border-gray-300 bg-transparent text-sm text-right w-full p-2"
                      @input="result.account.job_title = work"
                    />
                  </div>

                  <!-- </div> -->
                </div>
                <div class="w-full px-2 mb-6">
                  <input
                    placeholder=" رقم الجوال*"
                    v-model="formData.mobile"
                    name="mobile"
                    type="text"
                    style="outline: none !important"
                    :class="hasError('mobile') ? 'is-invalid' : ''"
                    @input="result.account.mobile = formData.mobile"
                    class="border h-12 rounded-md flex items-start border-gray-300 bg-transparent text-sm text-right w-full p-2"
                  />
                  <div
                    v-if="hasError('mobile')"
                    class="invalid-feedback text-right m-2"
                  >
                    <div class="error" v-if="!$v.formData.mobile.required">
                      رقم الجوال مطلوب
                    </div>
                    <div class="error" v-else-if="!$v.formData.mobile.numeric">
                      يجب ادخال رقم هاتف صحيح
                    </div>
                  </div>
                </div>
                <div class="w-full px-2 mb-6">
                  <input
                    placeholder="البريد الإلكتروني"
                    v-model="formData.email"
                    name="email"
                    id="email"
                    type="email"
                    @input="result.account.email = formData.email"
                    style="outline: none !important"
                    class="border h-12 rounded-md flex items-start border-gray-300 bg-transparent text-sm text-right w-full p-2"
                    :class="hasError('email') ? 'is-invalid' : ''"
                  />
                  <div
                    v-if="hasError('email')"
                    class="invalid-feedback text-right m-2"
                  >
                    <div class="error" v-if="!$v.formData.email.required">
                      البريد الإلكتروني مطلوب
                    </div>
                    <div class="error" v-else-if="!$v.formData.email.email">
                      يجب ادخال بريد إلكتروني صحيح
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </tab-content>
      <tab-content title="مقدمة">
        <div class="flex flex-col mx-auto mb-6">
          <div
            class="font-extrabold text-primary text-right my-2"
            style="font-size: 18px"
          >
            التعليمات الإرشادات
          </div>
          <div
            v-for="(item, i) in itemsInfo"
            :key="i"
            class="text-secondary flex items-center justify-end px-2 text-right mb-3"
          >
            <div class="w-full" style="font-size: 13px" v-html="item">
              <!-- {{ item }} -->
            </div>
            <div>
              <img
                src="/images/Icon Placeholder 2.svg"
                alt=""
                class="m-2"
                style="width: 28px; height: 28px"
              />
            </div>
          </div>
        </div>
        <div>
          <!-- <div
            class="font-extrabold text-primary text-right my-2"
            style="font-size: 18px"
          >
            الشخصيات في المقايس
          </div> -->
          <img src="/images/نماذج مقياس التفكير الحاسوبي بدون اسئلة-01.png" />
        </div>
        <div v-if="examples.length == 0" class="loader flex"></div>
        <div
          v-else
          v-for="(item, i) in examples"
          :key="i"
          class="mb-3 flex flex-col items-end border-b border-secondary pb-3 border-last"
        >
          <div class="mb-4l sm:flex-row flex flex-col-reverse">
            <p class="text-xl text-right sm:mt-0 mt-3">{{ item.name }}</p>

            <div class="mx-2 text-xl text-right">:المثال({{ i + 1 }})</div>
          </div>
          <div class="mb-3 w-full">
            <img :src="item.image" alt="" />
          </div>
          <div v-for="(ans, k) in item.answers" :key="k" class="pb-3">
            <div class="w-full px-2 mb-1">
              <label :for="ans.id" class="px-3">{{ ans.content }} </label>

              <input
                type="radio"
                :id="ans.id"
                :name="item.id"
                :value="ans.id"
                @change="checkAns(ans)"
              />
            </div>
          </div>
        </div>
        <div class="w-full px-2 mb-6">
          <input
            placeholder="  companyName*"
            v-model="formData.companyName"
            name="companyName"
            type="text"
            style="outline: none !important; visibility: hidden"
            :class="hasError('companyName') ? 'is-invalid' : ''"
            @input="formData.companyName"
            class="border h-12 rounded-md flex items-start border-gray-300 bg-transparent text-sm text-right w-full p-2"
          />
          <div
            v-if="hasError('companyName')"
            class="invalid-feedback text-right m-2"
          >
            <div class="error" v-if="!$v.formData.companyName.required">
              <div
                class="absolute bottom-8 right-16 flex items-center p-6 bg-white rounded-lg"
                style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)"
              >
                نأمل الإجابة على الأمثلة الموضحة إجابة صحيحة

                <img
                  class="mx-3"
                  src="/images/OOjs_UI_icon_alert-warning.svg.png"
                  style="width: 35px; height: 35px"
                />
              </div>
            </div>
          </div>
        </div>
      </tab-content>
      <tab-content title="أسئلة المقياس">
        <div v-if="items1.length == 0" class="loader"></div>

        <div
          v-else
          v-for="(item, i) in items1"
          :key="i"
          class="mb-3 flex flex-col items-end border-b border-secondary pb-3 border-last"
        >
          <div class="mb-4l sm:flex-row flex flex-col-reverse">
            <p class="text-xl text-right sm:mt-0 mt-3">{{ item.name }}</p>

            <div class="mx-2 text-xl text-right">:السؤال({{ item.id }})</div>
          </div>
          <div class="mb-3 w-full">
            <img :src="item.image" alt="" />
          </div>
          <div v-for="(ans, k) in item.answers" :key="k">
            <div class="w-full px-2 mb-1">
              <label :for="ans.id" class="px-3">{{ ans.content }} </label>

              <input
                type="radio"
                :id="ans.id"
                :name="item.id"
                :value="ans.id"
                @change="getResult(item.id, ans.id)"
              />
            </div>
          </div>
        </div>
      </tab-content>
      <!-- <tab-content title="القسم الثاني من الاسئلة">
        <div v-if="items2.length == 0" class="loader"></div>

        <div
          v-for="(item, i) in items2"
          :key="i"
          class="mb-3 flex flex-col items-end border-b border-secondary pb-3 border-last"
        >
          <div class="mb-4l sm:flex-row flex flex-col-reverse">
            <p class="text-xl text-right sm:mt-0 mt-3">{{ item.name }}</p>

            <div class="mx-2 text-xl text-right">:المثال({{ item.id }})</div>
          </div>
          <div class="mb-3 w-full">
            <img :src="item.image" alt="" />
          </div>
          <div v-for="(ans, k) in item.answers" :key="k">
            <div class="w-full px-2 mb-1">
              <label :for="ans.id" class="px-3">{{ ans.content }} </label>

              <input
                type="radio"
                :id="ans.id"
                :name="item.id"
                :value="ans.id"
                @change="getResult(item.id, ans.id)"
              />
            </div>
          </div>
        </div>
      </tab-content>
      <tab-content title="القسم الثالث من الاسئلة">
        <div v-if="items3.length == 0" class="loader"></div>

        <div
          v-for="(item, i) in items3"
          :key="i"
          class="mb-3 flex flex-col items-end border-b border-secondary pb-3 border-last"
        >
          <div class="mb-4l sm:flex-row flex flex-col-reverse">
            <p class="text-xl text-right sm:mt-0 mt-3">{{ item.name }}</p>

            <div class="mx-2 text-xl text-right">:المثال({{ item.id }})</div>
          </div>
          <div class="mb-3 w-full">
            <img :src="item.image" alt="" />
          </div>
          <div v-for="(ans, k) in item.answers" :key="k">
            <div class="w-full px-2 mb-1">
              <label :for="ans.id" class="px-3">{{ ans.content }} </label>

              <input
                type="radio"
                :id="ans.id"
                :name="item.id"
                :value="ans.id"
                @change="getResult(item.id, ans.id)"
              />
            </div>
          </div>
        </div>
      </tab-content>
      <tab-content title="القسم الرابع من الاسئلة">
        <div v-if="items4.length == 0" class="loader"></div>

        <div
          v-for="(item, i) in items4"
          :key="i"
          class="mb-3 flex flex-col items-end border-b border-secondary pb-3 border-last"
          :class="{}"
        >
          <div class="mb-4l sm:flex-row flex flex-col-reverse">
            <p class="text-xl text-right sm:mt-0 mt-3">{{ item.name }}</p>

            <div class="mx-2 text-xl text-right">:المثال({{ item.id }})</div>
          </div>
          <div class="mb-3 w-full">
            <img :src="item.image" alt="" />
          </div>
          <div v-for="(ans, k) in item.answers" :key="k">
            <div class="w-full px-2 mb-1">
              <label :for="ans.id" class="px-3">{{ ans.content }} </label>

              <input
                type="radio"
                :id="ans.id"
                :name="item.id"
                :value="ans.id"
                @change="getResult(item.id, ans.id)"
              />
            </div>
          </div>
        </div>
      </tab-content> -->
    </form-wizard>
  </section>
</template>

<script>
import { ValidationHelper } from "vue-step-wizard";
import "vue-step-wizard/dist/vue-step-wizard.css";

import { required } from "vuelidate/lib/validators";
import { email } from "vuelidate/lib/validators";
import { numeric } from "vuelidate/lib/validators";

export default {
  mixins: [ValidationHelper],

  data: () => ({
    work: "",
    educationalٍtages: [
      { name: "الدراسات العليا", id: "5" },
      { name: "المرحلة الجامعية", id: "4" },
      { name: "المرحلة الثانوية", id: "3" },
      { name: "المرحلة المتوسطة", id: "2" },
      { name: "المرحلة الإبتدائية", id: "1" },
    ],
    stu: "",
    itemsInfo: [
      ".يتكون المقياس من (20) سؤالًا.لكل سؤال 4 خيارات متعددة (أ – ب – ج – د )، أحد هذه الإجابات هو الإجابة الصحيحة فقط.",
      "زمن المقياس هو 45 دقيقة حين البدء",
      "ليس بالضرورة أن تجيب على جميع الأسئلة",
      "يمكنك التنقل بين صفحات المقياس من خلال زري السابق والتالي ",
      "في حال الانتهاء من المقياس اضغط على زر (إنهاء المقياس)",
      " لتصغيره 'CTRL-'وعلى ,'CTRL+'إذا كنت بحاجة لتكبير أي سؤال فاضغط على",
      "قبل بدء بالمقياس، أمامك (3) أمثلة توضيحية لطريقة الإجابة حتى تتمكن من التعرف على طبيعة الأسئلة التي ستجدها، والشخصيات  المستخدمة بها.",
    ],
    timerCount: -1,

    name: "",
    email: "",
    mobile: "",
    age: "",
    female: "",
    male: "",
    jop: "",
    num_page: 1,
    examples: [],
    items1: [],
    items2: [],
    items3: [],
    items4: [],
    step: 1,
    result2: {},
    result: {
      account: {},
      answers: [],
    },
    loader: true,
    loader2: true,
    loaderSub: false,
    formData: {
      fullName: "",
      email: null,
      companyName: null,
      numberOfEmployees: null,
      referral: null,
      terms: false,
      mobile: null,
    },
    validationRules: [
      { mobile: { required, numeric }, email: { required, email } },
      { companyName: { required } },
      // { referral: { required }, terms: { required } },
    ],
    answersIntro: [],
  }),
  methods: {
    submit() {
      this.loaderSub = true;
      const answers = [];

      Object.entries(this.result2).map(([questionId, answerId]) => {
        answers.push({
          question_id: questionId,
          answer_id: answerId,
        });
      });

      this.$axios
        .post("v1/exam/store", {
          account: this.result.account,
          answers,
        })
        .then((data) => {
          this.loaderSub = false;

          console.log("data12", data);
          localStorage.setItem("account_id", data.data.data.account_id);
          localStorage.setItem("exam_id", data.data.data.exam_id);
        });
      this.$router.push({ name: "Result" });
    },
    previousStep() {
      var element = this.$refs["art1"];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    getQus() {
      this.$axios.get(`v1/exam/examples`).then((data) => {
        this.examples = data.data.data;
      });
      this.$axios
        .get(`v1/exam/questions?page=${this.num_page}`)
        .then((data) => {
          this[`items${this.num_page}`] = data.data.data;
          this.loader2 = false;

          // this.$set(`items${this.num_page}`, data.data);
          this.num_page++;
        });
    },
    nextStep() {
      this.step++;

      if (this.step == 3) {
        this.timerCount = 45;
      }
      //   if (this.step == 1) {
      //     this.$axios.get(`v1/exam/examples`).then((data) => {
      //       this.examples = data.data.data;
      //       this.step++;
      //     });
      //   } else {
      //     this.$axios
      //       .get(`v1/exam/questions?page=${this.num_page}`)
      //       .then((data) => {
      //         this[`items${this.num_page}`] = data.data.data;
      //         this.loader2 = false;
      //         if (this.num_page == 1) {
      //           this.timerCount = 45;
      //         }
      //         // this.$set(`items${this.num_page}`, data.data);
      //         this.num_page++;
      //         this.step++;
      //       });

      //  }

      var element = this.$refs["art1"];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    getResult(id, id2) {
      //  this.result.answers.includes()
      //   this.result.answers.push({ [id]: id2 });

      this.result2 = { ...this.result2, [id]: id2 };
    },
    checkAns(data) {
      const quesNum = this.examples.length;
      console.log("data", data);
      if (data.is_correct == true) {
        this.$toasted.show("إجابة صحيحة");
        if (!this.answersIntro.includes(data.id)) {
          this.answersIntro.push(data.id);
        }
      } else {
        this.$toasted.show("إجابة خاطئة");
      }
      console.log("examples", this.answersIntro);
      if (quesNum == this.answersIntro.length) {
        this.formData.companyName = 3;
      }
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 60000);
        }
        if (value == 0) {
          this.submit();
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  mounted() {
    this.getQus();
  },
};
</script>

<style>
.vue-step-wizard {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}
.step-header {
  width: 22%;
}
.step-body {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin-right: auto;
}
.step-footer {
  display: block;
  width: 100%;
}
.vue-step-wizard {
  width: 100% !important;
}
.step-pills {
  display: block !important;
}
.step-pills li {
  margin-bottom: 10px;
}
.step-pills .step-item a {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.step-pills .step-item {
  background: #f9fafb;
}
.step-pills .step-item a span {
  margin-left: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.step-pills .step-item.active {
  border: 1px solid #67bb80;
  background: #67bb80;
}
.step-pills .step-item.active a {
  color: white;
}
.btn-group {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.step-button-previous:after {
  content: "السابق" !important;
  text-indent: 0;
  display: block;
  line-height: initial; /* New content takes up original line height */
}
.step-button-previous {
  text-indent: -9999px;
  line-height: 0;
  display: block;
  background: #386995;
  color: white;
  text-align: center;
  padding: 10px 0;
  border-radius: 16px;
  width: 144px;
  height: 56px;
  margin-left: 50px;
  box-shadow: 0px 7px #67bb80 !important;
}
.step-progress {
  display: none !important;
}
.step-button-next:after {
  content: "التالي" !important;
  text-indent: 0;
  display: block;
  line-height: initial; /* New content takes up original line height */
}
.step-button-next {
  text-indent: -9999px;
  line-height: 0;
  display: block;
  background: #386995;
  color: white;
  text-align: center;
  padding: 10px 0;
  border-radius: 16px;
  width: 144px;
  height: 56px;
  margin-left: 50px;
  box-shadow: 0px 7px #67bb80 !important;
}
.step-button-submit:after {
  content: "إنهاء المقياس" !important;
  text-indent: 0 !important;
  display: block !important;
  line-height: initial !important;
}
.step-button-submit {
  text-indent: -9999px;
  line-height: 0;
  display: block;
  background: #386995;
  color: white;
  text-align: center;
  padding: 10px 0;
  border-radius: 16px;
  width: 144px;
  height: 56px;
  margin-left: 50px;
  box-shadow: 0px 7px #67bb80 !important;
}
input[type="radio"] {
  appearance: none;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  outline: 2px solid #67bb80;
  outline-offset: 2px;
}
input[type="radio"]:checked {
  background: #67bb80;
}
.vue-step-wizard {
  background: white !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.tabStatus {
  color: #386995 !important;
  background: #f2f6ff !important;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error {
  color: red;
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin-top: 15%;
  margin-left: 45%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 992px) {
  .step-header {
    width: 100% !important;
    margin-bottom: 40px !important;
  }
  .step-body {
    width: 100% !important;
  }
  .vue-step-wizard {
    padding: 15px !important;
  }
  .font-custom {
    font-size: 30px !important;
  }
}
.border-last:last-child {
  border: 0 !important;
}
.timer-count {
  width: 100px;
  height: 100px;
  border: 7px solid #67bb80;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  font-weight: bold;
  color: #386995;
  margin: 0px 60px;
  /* background: #67bb8073; */
}
</style>
